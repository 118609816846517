import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMatomo from 'vue-matomo'

Vue.use(VueRouter)

const options = [
  { path: '/', component: 'HomePage', name: 'Manager', props: false },
  { path: '/login', component: 'UserLogin', name: 'Login', props: false },
  { path: '/settings', component: 'AdjustSettings', name: 'Configurações', props: false },
  { path: '/about', component: 'AboutProject', name: 'Sobre', props: false },
  {
    path: '/main',
    component: 'UserScreen',
    name: 'Manager - User',
    children: [
      { path: 'about', component: () => import('@/views/WelcomeUser.vue'), name: 'Página Inicial' },
      { path: 'hello', component: () => import('@/views/cruds/HomeUser.vue'), name: 'Hello' },
      { path: 'system/user', component: () => import('@/views/system/SystemUser.vue'), name: 'Usuários do Sistema' },
      { path: 'system/log', component: () => import('@/views/system/ViewLog.vue'), name: 'Logs' },
      { path: 'system/error', component: () => import('@/views/system/ViewError.vue'), name: 'Erros do Sistema' },
      { path: 'unit', component: () => import('@/views/cruds/CrudUnit.vue'), name: 'Unidades da Embrapa' },
      { path: 'publication-type', component: () => import('@/views/cruds/CrudPublicationType.vue'), name: 'Tipos de Publicação' },
      { path: 'area', component: () => import('@/views/cruds/CrudArea.vue'), name: 'Áreas de Atuação' },
      { path: 'director', component: () => import('@/views/cruds/CrudDirector.vue'), name: 'Diretores' },
      { path: 'question', component: () => import('@/views/cruds/CrudQuestion.vue'), name: 'Satisfação' },
      { path: 'building', component: () => import('@/views/cruds/CrudBuilding.vue'), name: 'Prédios' },
      { path: 'building/:id/service', component: () => import('@/views/cruds/CrudService.vue'), name: 'Serviços' },
      { path: 'building/:id/team', component: () => import('@/views/cruds/CrudTeam.vue'), name: 'Serviços' },
      { path: 'building/:id/equipment', component: () => import('@/views/cruds/CrudEquipment.vue'), name: 'Equipamentos' },
      { path: 'building/:id/environment', component: () => import('@/views/cruds/CrudEnvironment.vue'), name: 'Ambientes' },
      { path: 'building/:id/publication', component: () => import('@/views/cruds/CrudPublication.vue'), name: 'Publicações' }
    ]
  },
  { path: '*', component: 'NotFound', name: 'Página Não Encontrada', props: false }
]

const routes = options.map(route => {
  return {
    path: route.path,
    component: () => import(`@/views/${route.component}.vue`),
    name: route.name,
    props: route.props,
    children: route.children
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes
})

Vue.use(VueMatomo, {
  host: 'https://hit.embrapa.io',
  siteId: process.env.VUE_APP_MATOMO_ID,
  router: router,
  preInitActions: [
    ['setCustomDimension', 1, process.env.VUE_APP_STAGE],
    ['setCustomDimension', 2, process.env.VUE_APP_VERSION]
  ]
})

export default router
